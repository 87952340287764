import React, { useContext } from 'react'
import {
  Box,
  BASE,
  BRICO,
  LG,
  PromoBubble,
  PROMO_BUBBLE_CONSTANTS,
  MarketingLabel,
  Text,
  PromoLabel,
  PROMO_LABEL_CONSTANTS,
  Flex,
  Tooltip,
  chemistryTheme
} from 'chemistry'
import { TranslateContext } from 'client/common/translator'

export const isLarge = sizes => sizes.includes('lg')

export const CardMarketingLabel = ({ labelText, url, metadata, sizes, hasCheckbox, sx, ...props }) => (
  <Box
    sx={{
      position: 'absolute',
      transformOrigin: 'top left',
      left: hasCheckbox ? '26px' : 0,
      top: 0,
      ...(isLarge(sizes)
        ? {
            [LG]: {
              transform: 'none',
              left: 'auto',
              top: 'auto',
              right: 0,
              bottom: 0
            }
          }
        : {}),
      ...sx
    }}
    {...props}
  >
    <MarketingLabel labelText={labelText} url={url} metadata={metadata} />
  </Box>
)

const TooltipTrigerSVG = props => (
  <svg width='12' height='13' viewBox='0 0 12 13' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M11.528 4.16415C11.2256 3.44913 10.7932 2.80758 10.2421 2.25789C9.691 1.70678 9.04946 1.27438 8.33585 0.971974C7.59538 0.65968 6.8097 0.5 6 0.5C5.1903 0.5 4.40462 0.658267 3.66415 0.971974C2.94913 1.27438 2.30758 1.70678 1.75789 2.25789C1.20678 2.809 0.774376 3.45054 0.471974 4.16415C0.158267 4.90462 0 5.6903 0 6.5C0 7.3097 0.158267 8.09538 0.471974 8.83585C0.774376 9.55087 1.20678 10.191 1.75789 10.7421C2.309 11.2932 2.95054 11.7256 3.66415 12.028C4.40462 12.3417 5.1903 12.5 6 12.5C6.8097 12.5 7.59538 12.3417 8.33585 12.028C9.05087 11.7256 9.69242 11.2932 10.2421 10.7421C10.7932 10.191 11.2256 9.54946 11.528 8.83585C11.8417 8.09538 12 7.3097 12 6.5C12 5.6903 11.8403 4.90462 11.528 4.16415ZM6 11.6507C3.15968 11.6507 0.84927 9.34032 0.84927 6.5C0.84927 3.65968 3.15968 1.34927 6 1.34927C8.84032 1.34927 11.1507 3.65968 11.1507 6.5C11.1507 9.34032 8.84032 11.6507 6 11.6507Z' />
    <path d='M6.43316 5.34131H5.57602V9.43928H6.43316V5.34131Z' />
    <path d='M6.56526 3.71465C6.56526 4.02553 6.31232 4.27989 6.00002 4.27989C5.68773 4.27989 5.43478 4.02553 5.43478 3.71465C5.43478 3.40236 5.68773 3.14941 6.00002 3.14941C6.31232 3.14941 6.56526 3.40236 6.56526 3.71465Z' />
  </svg>
)

export const CardSponsoredLabel = () => {
  const t = useContext(TranslateContext)
  return (
    <Flex gap='4px' alignItems='center' my='4px'>
      <Text color='grey.725' fontSize='10px' lineHeight='15px' letterSpacing='0.25px'>
        {t('lister.card.sponsored.title')}
      </Text>
      <Tooltip
        padding='8px 12px'
        width='192px'
        content={
          <Text fontSize='12px' lineHeight='18px' letterSpacing='0.25px'>
            {t('lister.card.sponsored.tooltip')}
          </Text>
        }
        onClick={e => e.preventDefault()}
      >
        <TooltipTrigerSVG fill={chemistryTheme.colors.grey[725]} />
      </Tooltip>
    </Flex>
  )
}

const getVlpBubbleStyles = ({ sizes, format }) => ({
  [BASE]: { display: 'none' },
  ...(isLarge(sizes) ? { [LG]: { display: 'block', position: 'absolute' } } : {}),
  ...(format === BRICO ? { top: '4px', left: '4px' } : { top: 0, left: 0 })
})
export const CardVlpBubble = ({ title, format, lang, sizes }) => {
  return (
    <Box sx={getVlpBubbleStyles({ format, sizes })}>
      <PromoBubble
        size={PROMO_BUBBLE_CONSTANTS.SMALL}
        colorScheme={PROMO_BUBBLE_CONSTANTS.FIXED_LOW_PRICE}
        content={{ title, supertext: '' }}
        format={format}
        lang={lang}
      />
    </Box>
  )
}

const getVlpMobileLabelStyles = sizes => ({
  [BASE]: { display: 'flex' },
  ...(isLarge(sizes) ? { [LG]: { display: 'none' } } : {})
})
export const CardVlpMobileLabel = ({ sizes, format, lang }) => {
  return (
    <PromoLabel
      size={PROMO_LABEL_CONSTANTS.SIZE.small}
      variant={PROMO_LABEL_CONSTANTS.VARIANT.alwaysLowPrice}
      format={format}
      lang={lang}
      sx={getVlpMobileLabelStyles(sizes)}
    />
  )
}
